<template lang="html">
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body form-card">

            <div class="form-group row">
              <div class="col-2">
                <label class="required">Giảm giá</label>
              </div>
              <div class="col-10">
                <el-input
                  placeholder="Giảm giá"
                  type="number"
                  v-model="form.discount"
                  :class="errors.has('discount') ? 'border-danger' : ''"
                  v-validate="'required'"
                  data-vv-name="discount"
                  data-vv-as="Giảm giá"
                >
                  <template slot="append">%</template>
                </el-input>
                <span class="text-danger" v-if="errors.has('discount')">{{ errors.first('discount') }}</span>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-2">
                <label class="required">Giảm tối đa</label>
              </div>
              <div class="col-10">
                <el-input
                  placeholder="Giảm tối đa"
                  type="number"
                  v-model="form.max_value_discount"
                  :class="errors.has('max_value_discount') ? 'border-danger' : ''"
                  v-validate="'required'"
                  data-vv-name="max_value_discount"
                  data-vv-as="Giảm tối đa"
                >
                  <template slot="append">đ</template>
                </el-input>
                <span class="text-danger" v-if="errors.has('max_value_discount')">{{ errors.first('max_value_discount') }}</span>
              </div>
            </div>

          </div>
        </div>

        <div class="card">
          <div class="card-body form-card">

            <div class="form-group row">
              <div class="col-2">
                <label class="required">Thời gian (tháng)</label>
              </div>
              <div class="col-10">
                <el-select v-model="timeSelected" multiple placeholder="Chọn thời gian" class="full-width">
                  <el-option
                    v-for="item in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="form-group row" v-if="timeSelected && timeSelected.length">
              <div class="col-2">
                <label>Giá bán</label>
              </div>
              <div class="col-10">
                <div class="custom-form-control" v-for="item in timeSelected">
                  <div class="col-3">
                    <span>{{ item }} tháng</span>
                  </div>
                  <div class="col-9">
                    <el-input placeholder="Giá bán" v-model="prices[item]">
                      <template slot="append">đ</template>
                    </el-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="container-fluid">
        <div class="page-action">
          <el-button @click="cancel">Quay lại</el-button>
          <el-button type="primary" @click="save">Lưu lại</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Message, Input, Button, Select, Option } from 'element-ui';

  export default {
    components: {
      ElInput: Input,
      ElButton: Button,
      ElSelect: Select,
      ElOption: Option,
      Message
    },

    data() {
      return {
        form: {},
        timeSelected: [],
        prices: {}
      }
    },

    mounted() {
      this.$store.dispatch('setPageTitle', 'Tạo mới');
      this.$store.dispatch('setCurrentActions', [{
        label: 'Lưu lại',
        type: 'primary',
        icon: '',
        callback: this.save
      }])
    },

    methods: {

      cancel() {
        this.$router.push({name: 'AllVip'});
      },

      save() {
        let self = this;
        let data = Object.assign({}, this.form);
        data.prices = Object.assign({}, this.prices);
        data.title = 'iVip';
        data.description = 'iVip package';

        this.$validator.validateAll().then(result => {
          if (!result) return;

          if (Object.keys(data.prices).length == 0) {
            return Message({ message: 'Chưa nhập giá bán', type: 'error' });
          }

          self.$store.dispatch('storeVip', data).then(res => {

            if (!res.success) {
              return Message({ message: res.message, type: 'error' });
            }

            Message({message: 'Tạo mới thành công', type: 'success'});

            setTimeout(() => {
              self.$router.push('/vip/' + res.data.id);
            }, 1500);

          }, err => {
            Message({
              dangerouslyUseHTMLString: true,
              message: err,
              type: 'error'
            });
          });

        });
      }
    },

    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
